import { Link } from "react-router-dom";
import { Box, Button } from "@mantine/core";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { FlexJusBet } from "../../../styles/sx-mixins";
import { HiMenuAlt1 } from "react-icons/hi";
import { SearchBar } from ".";

//

const TopHeader = ({ isSideNavVisible, setDrawerOpened }) => {
	const session = useAuthUser();
	const signOut = useSignOut();
	//console.log(session());

	// if(session) {
	// 	return <>
	// 	  Signed in as {session.user.email} <br/>
	// 	  <button onClick={() => signOut()}>Sign out</button>
	// 	</>
	//   }

	return (
		<Box
			component="header"
			sx={{
				paddingRight: "40px",
				marginTop: "28px",
				marginBottom: "20px",

				"@media (max-width: 1315px)": { marginTop: "18px", padding: "0 15px" },
				"@media (max-width: 434px)": { padding: "0 10px" },
			}}
		>
			<Box sx={{ ...FlexJusBet, gap: "20px", "@media (max-width: 468px)": { gap: "12px" } }}>
				{/* hamburger menu for below 1315px screens */}
				{isSideNavVisible || (
					<Button sx={{ padding: "6px", svg: { width: "23px", height: "23px" } }} onClick={() => setDrawerOpened(true)}>
						<HiMenuAlt1 />
					</Button>
				)}

				<Box className="left-col"></Box>

				<Box className="right-col" sx={{ ...RightNavFlexStyles, flex: 1 }}>
					<Box sx={{ ...RightNavFlexStyles, flex: 1, "@media (max-width: 630px)": { display: "none" } }}>
						<SearchBar flex={0.8} />
					</Box>

					{!session()?.token? (
						<Button
							component={Link}
							to="/auth"
							sx={{ fontSize: "16px", height: "50px", padding: "10px", borderRadius: "10px" }}
						>
							Login
						</Button>
					) : (
						<Button
							component={Link}
							to="/"
							onClick={signOut}
							sx={{ fontSize: "16px", height: "50px", padding: "10px", borderRadius: "10px" }}
						>
							Logout
						</Button>
					)}
				</Box>
			</Box>

			<Box
				className="mobile-search-bar"
				sx={{ ...RightNavFlexStyles, marginTop: "12px", "@media (min-width: 630px)": { display: "none" } }}
			>
				<SearchBar />
			</Box>
		</Box>
	);
};

const RightNavFlexStyles = {
	display: "flex",
	alignItems: "stretch",
	justifyContent: "flex-end",
	gap: "20px",

	"@media (max-width: 668px)": { gap: "12px" },
};

export default TopHeader;
