import { useEffect, useState } from "react";
import { Box, Drawer } from "@mantine/core";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { SideNavbar, TopHeader } from "./sub-components";

//

const MainLayout = ({ children }) => {
	const [drawerOpened, setDrawerOpened] = useState(false);
	const isSideNavVisible = useMediaQuery("(min-width: 1315px)");
	const { pathname } = useLocation();

	useEffect(() => {
		if (!isSideNavVisible) setDrawerOpened(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return (
		<Box
			sx={{
				fontFamily: "Poppins, Montserrat, Lato, sans-serif",
				display: "flex",
				backgroundColor: "#FAFAFA",
				minHeight: "100vh",
			}}
		>
			{/* sidenav for above 1315px screens */}
			{isSideNavVisible && (
				<Box sx={{ position: "fixed", left: 0, top: 0, bottom: 0 }}>
					<SideNavbar />
				</Box>
			)}

			<Box
				sx={{
					flex: 1,
					"@media (min-width: 1315px)": { paddingLeft: "272px" },
				}}
			>
				<TopHeader isSideNavVisible={isSideNavVisible} setDrawerOpened={setDrawerOpened} />

				{/* Page Content */}
				<main>{children}</main>
			</Box>

			<Drawer
				opened={drawerOpened}
				onClose={() => setDrawerOpened(false)}
				withCloseButton={false}
				styles={{ drawer: { width: "240px", maxHeight: "100vh" } }}
			>
				<SideNavbar setDrawerOpened={setDrawerOpened} />
			</Drawer>
		</Box>
	);
};

export default MainLayout;
