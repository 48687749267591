import { useLocation, Link } from "react-router-dom";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { Navbar, UnstyledButton, Group, ThemeIcon, Text, Anchor, Box } from "@mantine/core";
import { HiClipboardCheck, HiQuestionMarkCircle, HiViewGrid } from "react-icons/hi";
import { BrandLogo, ProfileIcon, ResultsIcon } from "../../SvgAssets";
import { MdLogin, MdLogout, MdSettings } from "react-icons/md";
import PropTypes from "prop-types";

//

const SideNavBar = () => {
	const session = useAuthUser();
	const signOut = useSignOut();

	return (
		<Box sx={{ minWidth: "240px", width: "240px", backgroundColor: "white" }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					paddingTop: "42px",
					paddingBottom: "52px",
					cursor: "pointer",
				}}
			>
				<Anchor component={Link} to="/" sx={{ minWidth: "165px", width: "205px", height: "36px" }}>
					<BrandLogo />
				</Anchor>
			</Box>

			<Navbar height="calc(100vh - 140px)" withBorder={false} sx={{ backgroundColor: "transparent", overflowY: "auto" }}>
				<Navbar.Section sx={{ marginBottom: "20px" }}>
					<NavigationLink title="Home" icon={<HiViewGrid />} url="/bills" />
					<NavigationLink title="Legislators" icon={<HiClipboardCheck />} url="/legislators" />
					<NavigationLink title="Search" icon={<ResultsIcon />} url="/results" />
					<NavigationLink title="FindMy" icon={<ResultsIcon />} url="/findmy" />
					<NavigationLink title="Profile" icon={<ProfileIcon />} url="/followed-legislators" />
					<NavigationLink title="Settings" icon={<MdSettings />} url="/settings" />
				</Navbar.Section>
				<Navbar.Section sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", flex: 1 }}>
					<NavigationLink title="Help" icon={<HiQuestionMarkCircle />} url="/help" />

					{session()?.token ? (
						<NavigationLink title="Log Out" onClick={signOut} icon={<MdLogout />} />
					) : (
						<NavigationLink title="Login" icon={<MdLogin />} url="/auth" />
					)}
				</Navbar.Section>
			</Navbar>
		</Box>
	);
};

//

const NavigationLink = ({ title, icon, url, onClick }) => {
	const { pathname } = useLocation();
	const isActiveLink = pathname.includes(url);

	return (
		<UnstyledButton
			{...(url && { component: Link, to: url })}
			onClick={onClick}
			sx={(theme) => {
				const textColor = isActiveLink ? theme.colors.primary[2] : "rgb(19 19 19 / 25%)";

				return {
					display: "block",
					width: "100%",
					color: textColor,
					padding: "8px 0",
					marginBottom: "14px",
					borderRadius: "sm",
					borderLeft: "3px solid",
					borderColor: isActiveLink ? textColor : "#fff",
					transition: "background-color 0.2s ease-in-out",
					svg: { width: "25px", height: "25px", fill: textColor, stroke: textColor },
					"&:hover": { backgroundColor: theme.colors.primary[0] },
				};
			}}
		>
			<Group>
				<ThemeIcon
					sx={{
						marginLeft: "38px",
						backgroundColor: "transparent",
					}}
				>
					{icon}
				</ThemeIcon>
				<Text sx={{ fontSize: "16px", letterSpacing: "0.2px" }}>{title}</Text>
			</Group>
		</UnstyledButton>
	);
};

NavigationLink.propTypes = {
	title: PropTypes.string.isRequired,
	url: PropTypes.string,
	icon: PropTypes.element.isRequired,
};

export default SideNavBar;
